<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="3">
            <b-form-group :label="$t('Ip ')" label-for="ip">
              <b-form-input
                id="role-name"
                v-model="ipFilter"
                class="form-control"
                placeholder="Please Enter Ip"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group :label="$t('User Name')" label-for="department">
              <b-form-input
                id="role-name"
                v-model="usernameFilter"
                class="form-control"
                placeholder="Please Enter Username"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button variant="outline-secondary" @click="resetFilter()">
              {{ $t("Reset") }}
            </b-button>
            <b-button variant="primary" class="ml-2" @click="searchFilter()">
              {{ $t("Search") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <b-table
        ref="refMembersDepartmentListTable"
        class="position-relative table-white-space mb-0"
        :items="fetchMemberDepartments"
        responsive
        :fields="tableColumns"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(ip)="data">
          <b-link>
            {{ data.item.ip }}
          </b-link>
        </template>

        <template #cell(sum_login)="data">
          {{ data.item.sum_login }}
        </template>

        <template #cell(user_list)="data">
          {{ data.item.user_list }}
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalMembersDepartments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Modal: Add Department -->
    <!-- <add-department-modal @refetch-data="refetchData" /> -->
    <!-- Modal: Update Department -->
    <!-- <update-department-modal
        :department-datas.sync="departmentDatas"
        @refetch-data="refetchData"
      /> -->
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime } from "@core/utils/filter";
import store from "@/store";
import memberStoreModule from "@/views/apps/member/memberStoreModule";
import useAuditIp from "@/views/apps/member/audit-ip/useAuditIp";

export default {
  components: {
    // AddDepartmentModal: () => import("./AddDepartmentModal.vue"),
    // updateDepartmentModal: () => import("./UpdateDepartmentModal.vue"),
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {},
  data() {
    return {
      departmentDatas: null,
    };
  },
  methods: {
    showAddDepartmentModal() {
      this.$bvModal.show("modal-add-department");
    },
    showUpdateDepartmentModal(data) {
      this.departmentDatas = data;
      this.$bvModal.show("modal-update-department");
    },
  },
  setup() {
    // Register module
    if (!store.hasModule("app-member"))
      store.registerModule("app-member", memberStoreModule);
    const {
      tableColumns,
      perPage,
      currentPage,
      totalMembersDepartments,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refMembersDepartmentListTable,
      membersDepartmentsAdd,
      membersDepartments,
      ipFilter,
      usernameFilter,
      platformFilter,

      fetchMemberDepartments,
      refetchData,
      resolveStatus,
      platformOptions,
      deviceOptions,
      searchFilter,
    } = useAuditIp();
    // const nameFilter = ref(null)
    const resetFilter = () => {
      ipFilter.value = "";
      usernameFilter.value = "";
    };

    return {
      tableColumns,
      perPage,
      currentPage,
      totalMembersDepartments,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refMembersDepartmentListTable,
      membersDepartmentsAdd,
      membersDepartments,
      ipFilter,
      usernameFilter,
      platformFilter,

      formatDateTime,

      resetFilter,
      fetchMemberDepartments,
      refetchData,
      resolveStatus,
      platformOptions,
      deviceOptions,
      searchFilter,
    };
  },
};
</script>
